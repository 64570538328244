import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

export default class AlignSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    this.refs = refs;

    // Set initial state
    this.state = Object.assign({}, this.checkMenuOverflow());

    this.containerWidth = 1280;
    this.submenuPadding = null;
    this.activatorPadding = null;

    // Set layout constants once from CSS
    this.setLayoutConstants();

    // Align menu once on load
    this.update();

    // Then again on resize
    this.bindResize();
  }

  setLayoutConstants() {
    const { submenu, activator } = this.refs;
    const submenuStyle = window.getComputedStyle(submenu);
    const activatorStyle = window.getComputedStyle(activator);

    this.submenuPadding = parseInt(submenuStyle.paddingLeft, 10);
    this.activatorPadding = parseInt(activatorStyle.paddingLeft, 10);
  }

  update = () => {
    const { submenu } = this.refs;
    const { willOverflow, overflow } = this.state;

    // Set the submenu to be at least as wide as the activator
    Object.assign(submenu.style, {
      minWidth: this.getActivatorWidth() + this.submenuPadding * 2 + "px",
    });

    willOverflow &&
      Object.assign(submenu.style, {
        left: -this.submenuPadding - overflow + "px",
      });
  };

  checkMenuOverflow() {
    // get the righthand edge of the submenu (prior to alignment)
    const submenuRight = this.getSubmenuBoxRight();
    // get the width of the bounding box in which the submenu must fit
    const maxWidth = this.getMaxWidth();

    const willOverflow = submenuRight >= maxWidth;
    const overflow = willOverflow ? submenuRight - maxWidth : null;

    return { willOverflow, overflow };
  }

  bindResize() {
    const debouncedAlign = debounce(() => {
      const isMobile = window.innerWidth < 950;
      !isMobile && this.setState(Object.assign({}, this.checkMenuOverflow()));
    }, 200);

    window.addEventListener("resize", debouncedAlign);
  }

  getActivatorWidth() {
    const activatorBox = this.refs.activator.getBoundingClientRect();

    return activatorBox.width - this.activatorPadding * 2;
  }

  getSubmenuBoxRight() {
    // get the lefthand position of the top-level nav item
    const activatorBox = this.refs.activator.getBoundingClientRect();
    const activatorPos = activatorBox.left;

    // get the width of the submenu
    const submenuBox = this.refs.submenu.getBoundingClientRect();
    const submenuWidth = submenuBox.width;

    // return the right edge of the submenu, subtracting the difference
    // in padding between activator and submenu (30 - 20)
    return activatorPos - 10 + submenuWidth;
  }

  getMaxWidth() {
    const windowWidth = document.body.clientWidth;

    const windowIsWider = windowWidth - this.containerWidth > 0;

    // return the width of the client,
    // or the righthand edge of the container
    // if client is wider than the container
    return windowIsWider
      ? windowWidth - (windowWidth - this.containerWidth) / 2
      : windowWidth;
  }
}
