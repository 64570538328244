import { submitForm } from "../../api/Form";

export default class RequestCallBackForm {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;
    this.bindEvents();
  }

  bindEvents() {
    this.refs.open.addEventListener("click", this.openDialog);
    this.refs.close.addEventListener("click", this.closeDialog);
    this.refs.cancel.addEventListener("click", this.closeDialog);

    this.refs.form.addEventListener("submit", this.handleSubmit);
  }

  openDialog = (event) => {
    const bodyEl = document.querySelector("body");
    const dialog = document.querySelector(".call-back-form-modal");

    if (dialog) {
      dialog.showModal();

      if (bodyEl) {
        bodyEl.classList.add("no-scroll");
      }
    }
  };

  closeDialog = (event) => {
    event.preventDefault();

    const bodyEl = document.querySelector("body");
    const dialog = document.querySelector(".call-back-form-modal");

    if (dialog) {
      dialog.close();

      if (bodyEl) {
        bodyEl.classList.remove("no-scroll");
      }

      this.refs.form.reset();
      this.refs.form.classList.remove("hidden");
      this.refs.form.removeAttribute("inert");
      this.refs.success.classList.remove("visible");
      this.refs.error.classList.remove("visible");
      this.refs.submit.removeAttribute("disabled", "");
    }
  };

  onSuccess = () => {
    this.refs.form.classList.add("hidden");
    this.refs.form.setAttribute("inert", "");
    this.refs.success.classList.add("visible");
    this.refs.submit.setAttribute("disabled", "");
    this.refs["success-focus"].focus();
  };

  onError = () => {
    this.refs.error.classList.add("visible");
    this.refs.error.textContent =
      "**There was an error submitting your information. Please try again or contact us directly.**";
    this.refs.error.focus();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const voicemail = formData.get("voicemail_ok");
    if (voicemail === "on") formData.set("voicemail_ok", 1);

    submitForm(formData, this.onSuccess, this.onError);
  };
}
