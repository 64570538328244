import parse from "url-parse";

export default class SetGlobalLanguage {
  constructor(el, props, refs) {
    el.addEventListener("change", this.handleLanguageChange);
  }

  handleLanguageChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const val = event.target.value;
    const url = parse(window.location);
    url.set("query", { locale: val });
    if (val === "en") {
      this.removeTranslateBar();
      url.set("hash", null);
    } else {
      url.set("hash", `googtrans(en|${val})`);
    }
    window.location = url.toString();
  };

  removeTranslateBar() {
    const translateToolbar = document.querySelector(".goog-te-banner-frame");
    if (!translateToolbar) return null;
    const close =
      translateToolbar.contentWindow.document.querySelector(".goog-close-link");
    if (close) return close.click();
  }
}
