import Sb from "../abstract/StatefulBehavior";
import Flickity from "flickity";
import classNames from "classnames";
import { ResizeObserver as RoPolyfill } from "@juggle/resize-observer";

const ResizeObserver = (window && window.ResizeObserver) || RoPolyfill;

export default class NavigateTableauPanels extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;

    import(/* webpackChunkName: "tableau" */ "../../lib/tableau-2.5.0").then(
      ({ default: tableau }) => {
        // if no slider, only one chart is present
        if (!refs.slider) return this.updateTableauViz();

        this.state = {
          active: 0,
          overflowing: this.isOverflowing,
        };
        this.slider = new Flickity(refs.slider, this.flickityOptions);
        this.initSliderClassName = refs.slider.className;
        this.resizeObserver = new ResizeObserver(this.handleResize);

        this.update();
        this.bindEvents();
      }
    );
  }

  get flickityOptions() {
    return {
      cellAlign: "left",
      selectedAttraction: 0.075,
      friction: 0.48,
      pageDots: false,
      adaptiveHeight: false,
      contain: true,
      percentPosition: false,
      draggable: ">3",
      watchCSS: true,
    };
  }

  get sliderWidth() {
    return this.refs.slider.getBoundingClientRect().width;
  }

  get containerWidth() {
    const elStyle = window.getComputedStyle(this.el);
    const { width, paddingLeft, paddingRight } = elStyle;
    return (
      parseFloat(width) - (parseFloat(paddingLeft) + parseFloat(paddingRight))
    );
  }

  get isOverflowing() {
    return this.sliderWidth > this.containerWidth;
  }

  get optionPrefix() {
    return "tableau-option-";
  }

  get tableauHost() {
    return "https://public.tableau.com";
  }

  update = () => {
    const { tab, panel, slider } = this.refs;
    const { active, overflowing } = this.state;

    tab.forEach((t, index) => {
      t.setAttribute("tab-index", index === 0 ? 0 : -1);
      t.setAttribute("aria-selected", index === active);
    });
    panel.forEach((p, index) => {
      p.hidden = index !== active;
    });
    slider.className = classNames(this.initSliderClassName, {
      "show-nav-buttons": overflowing,
    });

    this.updateTableauViz();
  };

  updateTableauViz() {
    const { panel, tableauContainer } = this.refs;
    const activePanel = panel[this.state.active] || panel;
    const url = activePanel.getAttribute("data-url");
    const height = this.getIframeHeight(
      activePanel.getAttribute("data-height")
    );
    const options = {
      width: "100%",
      height,
    };

    if (this.viz) this.viz.dispose();

    if (!url) return;

    this.viz = new window.tableau.Viz(tableauContainer, url, options);
  }

  bindEvents() {
    const { tab, select } = this.refs;
    this.refs.tab.forEach((t, index) => {
      t.addEventListener("click", () => this.handleClick(index));
    });
    this.resizeObserver.observe(this.refs.slider);
    if (select) {
      select.addEventListener("change", this.handleChange);
    }
  }

  handleClick(index) {
    this.setState({ active: index });
  }

  handleChange = (event) => {
    const index = event.target.value.replace(this.optionPrefix, "");
    this.setState({ active: parseFloat(index) });
  };

  handleResize = (entries) => {
    entries.forEach((entry) => {
      const boxWidth = entry.contentRect.width;
      this.setState({ overflowing: boxWidth > this.containerWidth });
    });
  };

  getIframeHeight(height) {
    if (height === "small") return "800px";
    if (height === "large") return "1600px";
    return "1200px";
  }
}
