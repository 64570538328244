export async function submitForm(formData, onSuccess, onError) {
  const url = "/api/v1/form";
  const response = await window.fetch(url, {
    method: "POST",
    body: formData,
  });

  const data = await response.json();

  if (response.status !== 200 || !data.success) {
    return onError(data);
  } else {
    if (onSuccess) {
      return onSuccess(data);
    }
  }
}
