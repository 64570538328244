import Sb from "../abstract/StatefulBehavior";

export default class SelectLanguage extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      downloadUrl: refs.select.options[1].value,
    };

    this.refs = refs;
    this.el = el;
    this.bindEvents();
    this.update();
  }

  update = () => {
    this.refs.download.setAttribute("href", this.state.downloadUrl);
  };

  bindEvents() {
    const { select } = this.refs;
    select.addEventListener("change", this.changeLink);
  }

  changeLink = (event) => {
    this.setState({ downloadUrl: event.target.value });
  };
}
