import Sb from "../abstract/StatefulBehavior";

export default class DismissNotification extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.refs = refs;
    this.state = {
      closed: false,
    };

    this.sessionKey = "hideNotificationHealthShare";

    // if UA doesn't support sessionStorage or blocks access to it,
    // hide dismiss button and bail
    try {
      this.session = sessionStorage.getItem(this.sessionKey);
      if (this.session) {
        this.hideElement();
        this.state.closed = !!this.session;
      }
    } catch (error) {
      // eslint-disable-next-line
      console.warn("Your browser does not support session keys.");
    }

    this.update();
    this.bindEvents();
  }

  update = () => {
    const { closed } = this.state;
    this.el.setAttribute("aria-hidden", closed);
    if (closed) {
      this.hideElement();
    }
  };

  bindEvents() {
    this.refs.toggleClosed.addEventListener("click", this.closeNotification);
  }

  closeNotification = () => {
    sessionStorage.setItem(this.sessionKey, true);
    this.setState({ closed: true });
  };

  hideElement = () => {
    Object.assign(this.el.style, { display: "none" });
  };
}
