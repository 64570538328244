import { submitForm } from "../../api/Form";

export default class RequestCallBackFormPage {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;
    this.bindEvents();
  }

  bindEvents() {
    this.refs.form.addEventListener("submit", this.handleSubmit);
  }

  onSuccess = () => {
    this.refs.form.classList.add("hidden");
    this.refs.form.setAttribute("inert", "");
    this.refs.success.classList.add("visible");
    this.refs.submit.setAttribute("disabled", "");
    this.refs["success-focus"].focus();
  };

  onError = () => {
    this.refs.error.classList.add("visible");
    this.refs.error.textContent =
      "**There was an error submitting your information. Please try again or contact us directly.**";
    this.refs.error.focus();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const voicemail = formData.get("voicemail_ok");
    if (voicemail === "on") formData.set("voicemail_ok", 1);

    submitForm(formData, this.onSuccess, this.onError);
  };
}
