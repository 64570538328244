import AlignSubmenu from "./AlignSubmenu";
import ControlSubmenus from "./ControlSubmenus";
import ToggleMobileNavs from "./ToggleMobileNavs";
import TargetLinks from "./TargetLinks";
import SetGlobalLanguage from "./SetGlobalLanguage";

export default {
  AlignSubmenu,
  ControlSubmenus,
  ToggleMobileNavs,
  TargetLinks,
  SetGlobalLanguage,
};
