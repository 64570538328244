import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import debounce from "lodash/debounce";

export default class ExpandAccordion extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
      height: refs.accordionToggle.offsetHeight,
      initClass: el.className,
      animating: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    // Update once on load
    this.update();
    this.bindToggle();
    this.bindResize();

    this.animationTimer = null;
  }

  update = (e) => {
    // Unbind opener on el if opened
    // If animating, set a timeout to cancel animation
    if (this.state.animating) {
      this.animationTimer = setTimeout(() => {
        this.setState({ animating: false });
      }, 350);
    }
    this.el.className = classNames(this.state.initClass, {
      open: this.state.open,
      closed: !this.state.open,
    });

    this.refs.accordionToggle.setAttribute(
      "aria-expanded",
      this.state.open ? "true" : "false"
    );

    Object.assign(this.el.style, { maxHeight: this.state.height + "px" });
  };

  bindToggle() {
    this.refs.accordionToggle.addEventListener("click", this.toggle);
  }

  bindResize() {
    const { accordionToggle, fullContent } = this.refs;
    const debouncedHeight = debounce(() => {
      const newHeight = this.state.open
        ? accordionToggle.offsetHeight + fullContent.offsetHeight
        : accordionToggle.offsetHeight;
      this.setState({ height: newHeight });
    }, 200);

    window.addEventListener("resize", debouncedHeight);
  }

  open = (e) => {
    // Only open if already open
    if (this.state.open || this.state.animating) {
      return false;
    }

    const { accordionToggle, fullContent } = this.refs;

    this.setState({
      open: true,
      height: accordionToggle.offsetHeight + fullContent.offsetHeight,
      animating: true,
    });
  };

  close = (e) => {
    const { accordionToggle } = this.refs;

    this.setState({
      open: false,
      height: accordionToggle.offsetHeight,
      animating: true,
    });
  };

  toggle = (e) => {
    // Don't update if animating
    if (this.state.animating) return false;
    // Don't click the wrapper also
    e.stopPropagation();
    this.state.open ? this.close() : this.open();
  };
}
