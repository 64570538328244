import ExpandAccordion from "./ExpandAccordion";
import NavigateTableauPanels from "./NavigateTableauPanels";
import ObjectFit from "./ObjectFit";
import SlideCards from "./SlideCards";
import SelectLanguage from "./SelectLanguage";
import TextFit from "./TextFit";
import LazyLoad from "./LazyLoad";
import DismissNotification from "./DismissNotification";
import RequestCallBackForm from "./RequestCallBackForm";
import RequestCallBackFormPage from "./RequestCallBackFormPage";

export default {
  ExpandAccordion,
  NavigateTableauPanels,
  ObjectFit,
  SlideCards,
  SelectLanguage,
  TextFit,
  LazyLoad,
  DismissNotification,
  RequestCallBackForm,
  RequestCallBackFormPage,
};
