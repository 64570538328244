import classNames from "classnames";
import Sb from "../abstract/StatefulBehavior";
import hoverintent from "hoverintent";

export default class RevealSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      hovering: false,
      animating: false,
      activatorClass: refs.activator.className,
      menuClass: refs.submenu.className,
    };

    // Create a class variable
    // To track for timeouts
    this.outTimer = "";

    this.refs = refs;
    this.props = props;
    this.el = el;

    this.bindEvents();
  }

  update = () => {
    const { activator, submenu } = this.refs;
    const state = this.state;

    activator.className = classNames(state.activatorClass, {
      active: state.hovering,
      inactive: !state.hovering,
    });

    submenu.className = classNames(state.menuClass, {
      active: state.hovering,
      inactive: !state.hovering,
      animating: state.animating,
    });

    // Ask parent to close siblings when open
    if (state.hovering) {
      this.props.onHover(this.props.id);
    }
  };

  hoverIn = (animate = false) => {
    // Prevent timeout from setting false
    const animating = !this.state.hovering && animate;
    clearTimeout(this.outTimer);
    this.setState({
      hovering: true,
      animating,
    });
  };

  hoverOut = (delay = true) => {
    // Ensure timout doesn't run twice
    clearTimeout(this.outTimer);

    if (delay) {
      this.outTimer = setTimeout(() => {
        this.setState({
          hovering: false,
          animating: false,
        });
      }, 300);
    } else {
      this.setState({
        hovering: false,
        animating: false,
      });
    }
  };

  close() {
    // Called by parent function to close all
    // but the active dropdown
    // Ensure that timer doesn't run after close
    clearTimeout(this.outTimer);
    this.setState({ hovering: false });
  }

  bindEvents() {
    const { activator, submenu } = this.refs;

    // show animation if not already hovering
    // set hovering to true
    hoverintent(
      activator,
      () => {
        this.hoverIn(true);
      },
      () => {
        return null;
      }
    ).options({ interval: 80 });

    activator.addEventListener("focus", (e) => {
      this.hoverIn(true);
    });

    // Set a timeout to close menu
    activator.addEventListener("mouseleave", (e) => {
      this.hoverOut(false);
    });
    activator.addEventListener("blur", (e) => {
      this.hoverOut(false);
    });

    // set hovering to true (without showing animation)
    submenu.addEventListener("mouseenter", this.hoverIn);
    submenu.addEventListener("focusin", this.hoverIn);

    // Set a timeout to close menu
    submenu.addEventListener("mouseleave", this.hoverOut);
    submenu.addEventListener("focusout", this.hoverOut);
  }
}
