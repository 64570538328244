import Sb from "../abstract/StatefulBehavior";

export default class SlideCards extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      active: 0,
      animating: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.slideLength =
      refs.cardWrapper.getElementsByClassName("card").length - 1;

    // Update once on load
    this.update();
    this.bindToggles();

    this.animationTimer = null;
  }

  update = (e) => {
    // If animating, set a timeout to avoid multiple clicks in quick succession
    if (this.state.animating) {
      this.animationTimer = setTimeout(() => {
        this.setState({ animating: false });
      }, 350);
    }

    Object.assign(this.refs.cardWrapper.style, {
      transform: `translateX(-${this.state.active}00%)`,
    });
  };

  bindToggles() {
    const { togglePrev, toggleNext } = this.refs;
    togglePrev.addEventListener("click", (event) => {
      event.stopPropagation();
      this.toggleSlider("prev");
    });
    toggleNext.addEventListener("click", (event) => {
      event.stopPropagation();
      this.toggleSlider("next");
    });
  }

  toggleSlider = (direction) => {
    // Don't update if animating
    if (this.state.animating) return false;

    let newActive = this.state.active;
    // determine the next active slide based on the toggling direction
    // and the current active slide
    if (direction === "prev") {
      newActive =
        this.state.active === 0 ? this.slideLength : this.state.active - 1;
    } else if (direction === "next") {
      newActive =
        this.state.active === this.slideLength ? 0 : this.state.active + 1;
    }

    this.setState({ animating: true, active: newActive });
  };
}
