import "core-js/stable";
import "regenerator-runtime/runtime";
import polyfill from "./javascript/helpers/polyfill";
import "focus-visible";
import "intersection-observer";
import "lite-youtube-embed/src/lite-yt-embed.js";
import "lite-youtube-embed/src/lite-yt-embed.css";
import "./style/stylesheets/styles.scss";
import Percolator from "./javascript/helpers/Percolator";

import behaviors from "./javascript/behaviors";

// Run local polyfills
polyfill.customEvent();

const percolator = new Percolator();

percolator.loadBehaviors(behaviors.site);
percolator.loadBehaviors(behaviors.content);
