import fitty from "fitty";

export default class TextFit {
  constructor(el, props) {
    const { maxSize } = props;
    const fitties = fitty(".fit", {
      maxSize: maxSize ? parseInt(maxSize, 10) : 79,
    });
    this.fitties = fitties;

    this.listenFitted();
    this.constrainText();
  }

  listenFitted() {
    const fittedElement = this.fitties[0].element;
    fittedElement.addEventListener("fit", () => {
      this.unhideElements();
    });
  }

  constrainText() {
    this.fitties[0].fit();
  }

  unhideElements() {
    const hiddenElements = document.getElementsByClassName("load-hidden");

    [...hiddenElements].forEach((element) => {
      element.classList.remove("load-hidden");
    });
  }
}
