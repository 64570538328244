/*
Polyfill for CSS property object-fit

Detects feature support in browser. If property is unsupported, load the image
src as a background image in the parent container
*/
import Sb from "../abstract/StatefulBehavior";

export default class ObjectFit extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      loaded: false,
    };

    this.el = el;
    this.props = props;
    this.image = refs.image;
    this.src = "";

    // Bail immediately if object-fit is supported
    if (el.style.objectFit !== undefined) return;

    // If unsupported, check if image is already loaded and cached.
    // If so, update state immediately. Otherwise listen for image load.
    this.image.complete ? this.setState({ loaded: true }) : this.loadImage();
  }

  update = () => {
    if (!this.state.loaded) return;

    // Attempt to get image from `srcset`, otherwise fallback to `src`
    const src =
      typeof this.image.currentSrc !== "undefined"
        ? this.image.currentSrc
        : this.image.src;

    if (this.src !== src) {
      this.src = src;

      Object.assign(this.image.style, { opacity: 0 });

      Object.assign(this.el.style, {
        backgroundImage: `url("${this.src}")`,
        backgroundSize: "cover",
      });

      // If prop for objectPosition are passed,
      // apply them to the background as backgroundPosition
      if (this.props.objectPosition) {
        const { x, y } = this.props.objectPosition;
        Object.assign(this.el.style, { backgroundPosition: `${x} ${y}` });
      } else {
        Object.assign(this.el.style, { backgroundPosition: `50% 50%` });
      }
    }
  };

  loadImage = () => {
    this.image.addEventListener("load", (e) => {
      this.setState({ loaded: true });
    });
  };
}
