import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import debounce from "lodash/debounce";
import ScrollLock from "cic-scroll-lock";

export default class ToggleMobileNavs extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      navActive: false,
      scrollLocked: false,
      isMobile: false,
    };

    this.refs = refs;
    this.overlayEl = undefined;
    this.scrollLock = new ScrollLock();

    this.createOverlayEl();
    this.updateNav();
    this.bindToggleEvents();
    this.bindResizeEvent();
  }

  createOverlayEl() {
    // create a transparent overlay element at the top of `body`
    // that will fade out all content except the active element (nav)
    // when scrollLock is enabled
    const firstChild = document.body.firstElementChild;
    this.overlayEl = document.createElement("div");
    this.overlayEl.className = "scroll-overlay";
    document.body.insertBefore(this.overlayEl, firstChild);
  }

  updateNav = () => {
    const { navToggle, navMenu, submenu, logo } = this.refs;
    const { navActive, scrollLocked } = this.state;

    navToggle.className = classNames("hamburger", {
      "nav-active": navActive,
      "nav-closed": !navActive,
    });

    navMenu.className = classNames("mobile-site-navigation", {
      open: navActive,
    });

    logo.className = classNames("logo", {
      "nav-active": navActive,
      "nav-closed": !navActive,
    });

    [...submenu].forEach((menu) => {
      !navActive && menu.classList.remove("open");
    });

    if (navActive) {
      window.addEventListener("keyup", this.closeOnEscape);
      this.disableScroll(navMenu);
    } else {
      window.removeEventListener("keyup", this.closeOnEscape);
      this.enableScroll(navMenu);
    }
  };

  bindToggleEvents() {
    const { navToggle, submenuOpen, submenuClose } = this.refs;

    navToggle.addEventListener("click", this.toggleNav);
    [...submenuOpen].forEach((button) => {
      button.addEventListener("click", this.openSubmenu);
    });
    [...submenuClose].forEach((button) => {
      button.addEventListener("click", this.closeSubmenu);
    });
  }

  bindResizeEvent() {
    const debouncedInnerWidth = debounce(() => {
      const isMobileWidth = window.innerWidth < 950;

      // If true, just update isMobile state to true.
      // Otherwise, set nav isMobile state to false and update,
      // since we want all menus to hide if the viewport expands to desktop.
      isMobileWidth
        ? this.setState({ isMobile: true }, null)
        : this.setState(
            {
              navActive: false,
              isMobile: false,
            },
            this.updateNav
          );
    }, 200);

    window.addEventListener("resize", debouncedInnerWidth);
  }

  closeOnEscape = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Esc" ||
      event.keyCode === 27 ||
      event.which === 27
    ) {
      this.setState({ navActive: false }, this.updateNav);
    }
  };

  toggleNav = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ navActive: !this.state.navActive }, this.updateNav);
  };

  openSubmenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const submenu = event.target.nextElementSibling;
    const search = document.querySelector(
      '[class="search search--mobile-nav"]'
    );
    this.scrollLock.any(submenu);
    submenu.classList.add("open");
    search.hidden = true;
    event.target.setAttribute("aria-expanded", "true");
  };

  closeSubmenu = (event) => {
    const { submenuOpen } = this.refs;
    event.preventDefault();
    event.stopPropagation();
    const submenu = event.target.parentNode;
    const search = document.querySelector(
      '[class="search search--mobile-nav"]'
    );
    submenu.classList.remove("open");
    search.hidden = false;
    [...submenuOpen].forEach((button) => {
      button.setAttribute("aria-expanded", "false");
    });
  };

  disableScroll = (activeRef) => {
    document.body.classList.add("no-scroll");
    this.overlayEl.classList.add("active");
    activeRef.classList.add("scroll-is-active");
    this.scrollLock.only(activeRef);
    this.setState({ scrollLocked: true }, null);
  };

  enableScroll = (activeRef) => {
    if (!this.state.scrollLocked) return;
    document.body.classList.remove("no-scroll");
    this.overlayEl.classList.remove("active");
    activeRef.classList.remove("scroll-is-active");
    this.scrollLock.any(activeRef);
  };
}
